"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalState = void 0;
class GlobalState {
    static doubleClickDetector() {
        if (GlobalState.doubleClickPending)
            return true;
        GlobalState.doubleClickPending = true;
        setTimeout(() => {
            GlobalState.doubleClickPending = false;
        }, 300);
        return false;
    }
}
exports.GlobalState = GlobalState;
GlobalState.doubleClickPending = false;
