"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StopTimeout = void 0;
class StopTimeout {
    static set(speech) {
        speech.stopTimeout = setTimeout(() => speech.stop(), speech.stopTimeoutMS);
    }
    static reset(speech, stopTimeoutMS) {
        speech.stopTimeoutMS = stopTimeoutMS || StopTimeout.DEFAULT_MS;
        if (speech.stopTimeout)
            clearTimeout(speech.stopTimeout);
        StopTimeout.set(speech);
    }
}
exports.StopTimeout = StopTimeout;
StopTimeout.DEFAULT_MS = 20000; // 20s
