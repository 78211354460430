"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebSpeechTranscript = void 0;
const translate_1 = require("../../utils/translate");
class WebSpeechTranscript {
    static extract(event, finalTranscript, translations) {
        let interimTranscript = '';
        for (let i = event.resultIndex; i < event.results.length; ++i) {
            let newText = event.results[i][0].transcript;
            if (translations)
                newText = translate_1.Translate.translate(newText, translations);
            if (event.results[i].isFinal) {
                finalTranscript += newText;
            }
            else {
                interimTranscript += newText;
            }
        }
        return { interimTranscript, finalTranscript, newText: interimTranscript || finalTranscript };
    }
    static extractSafari(event, _, translations) {
        let finalTranscript = '';
        const interimTranscript = '';
        for (let i = event.resultIndex; i < event.results.length; ++i) {
            let newText = event.results[i][0].transcript;
            if (translations)
                newText = translate_1.Translate.translate(newText, translations);
            finalTranscript += newText;
        }
        return { interimTranscript, finalTranscript, newText: interimTranscript || finalTranscript };
    }
}
exports.WebSpeechTranscript = WebSpeechTranscript;
