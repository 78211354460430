"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutoScroll = void 0;
// Strategy:
// This is only used for generic elements
// Originally used element.scrollIntoView(false) - however it was scrolling the entire screen
// scrollIntoView({block: 'nearest'}) worked - but if the span covers multiple lines the scroll won't go down to new text
// Scrolling is facilitated by adding a scrollingSpan element after the interimSpan that acts like padding and
// we scroll to it
// When the cursor is at end (isCursorAtEnd) - we don't need scrollingSpan and simply force scroll to bottom
// scrollingSpan is ultimately only used when auto scrolling is required and cursor is not at end:
// one accepted behavioural caviat is that text will always have right padding - due to &nbsp;
class AutoScroll {
    static changeStateIfNeeded(speech, isAutoScrollingRequired) {
        if (isAutoScrollingRequired && !speech.isCursorAtEnd) {
            speech.endPadding = '';
            // to scroll to span - it needs to have text (' ' does not work in spans), hence &nbsp; is a placeholder space
            speech.scrollingSpan.innerHTML = '&nbsp;';
        }
    }
    static scrollGeneric(speech, element) {
        if (speech.isCursorAtEnd) {
            element.scrollTop = element.scrollHeight;
        }
        else {
            speech.scrollingSpan.scrollIntoView({ block: 'nearest' });
        }
    }
    // primitives don't need to be scrolled except in safari
    // they can only safely be scrolled to the end
    static scrollSafariPrimitiveToEnd(element) {
        element.scrollLeft = element.scrollWidth;
        element.scrollTop = element.scrollHeight;
    }
    static isElementOverflown(element) {
        return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
    }
    static isRequired(autoScroll, element) {
        return autoScroll && AutoScroll.isElementOverflown(element);
    }
}
exports.AutoScroll = AutoScroll;
