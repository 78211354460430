"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Elements = void 0;
class Elements {
    static isPrimitiveElement(element) {
        return element.tagName === 'INPUT' || element.tagName === 'TEXTAREA';
    }
    static createInterimSpan() {
        const span = document.createElement('span');
        span.style.color = 'grey';
        span.style.pointerEvents = 'none';
        return span;
    }
    static createGenericSpan() {
        const span = document.createElement('span');
        span.style.pointerEvents = 'none';
        return span;
    }
    static appendSpans(speech, element) {
        speech.spansPopulated = true;
        if (speech.insertInCursorLocation && document.activeElement === element) {
            const selection = window.getSelection();
            if (selection === null || selection === void 0 ? void 0 : selection.focusNode) {
                const newRange = selection.getRangeAt(0);
                newRange.insertNode(speech.scrollingSpan);
                newRange.insertNode(speech.interimSpan);
                newRange.insertNode(speech.finalSpan);
                newRange.collapse(false);
                selection.removeAllRanges();
                selection.addRange(newRange);
                return;
            }
        }
        element.appendChild(speech.finalSpan);
        element.appendChild(speech.interimSpan);
        element.appendChild(speech.scrollingSpan);
    }
    static applyCustomColors(speech, color) {
        if (color.interim)
            speech.interimSpan.style.color = color.interim;
        if (color.final)
            speech.finalSpan.style.color = color.final;
    }
    static isInsideShadowDOM(element) {
        return element.getRootNode() instanceof ShadowRoot;
    }
}
exports.Elements = Elements;
