"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Translate = void 0;
const text_1 = require("./text");
class Translate {
    static translate(text, tranlsations) {
        const words = text_1.Text.breakupIntoWordsArr(text);
        for (let i = 0; i < words.length; i += 1) {
            if (tranlsations[words[i]]) {
                words[i] = tranlsations[words[i]];
            }
        }
        return words.join('');
    }
}
exports.Translate = Translate;
