"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const webSpeech_1 = require("./services/webSpeech/webSpeech");
const commandUtils_1 = require("./utils/commandUtils");
const globalState_1 = require("./utils/globalState");
const azure_1 = require("./services/azure/azure");
class SpeechToElement {
    static toggle(service, options) {
        var _a, _b;
        const processedServiceName = service.toLocaleLowerCase().trim();
        if ((_a = globalState_1.GlobalState.service) === null || _a === void 0 ? void 0 : _a.recognizing) {
            this.stop();
        }
        else if (processedServiceName === 'webspeech') {
            SpeechToElement.startWebSpeech(options);
        }
        else if (processedServiceName === 'azure') {
            SpeechToElement.startAzure(options);
        }
        else {
            console.error("service not found - must be either 'webspeech' or 'azure'");
            (_b = options === null || options === void 0 ? void 0 : options.onError) === null || _b === void 0 ? void 0 : _b.call(options, "service not found - must be either 'webspeech' or 'azure'");
        }
    }
    static startWebSpeech(options) {
        if (SpeechToElement.stop())
            return;
        globalState_1.GlobalState.service = new webSpeech_1.WebSpeech();
        globalState_1.GlobalState.service.start(options);
    }
    static isWebSpeechSupported() {
        return !!webSpeech_1.WebSpeech.getAPI();
    }
    static startAzure(options) {
        var _a;
        if (SpeechToElement.stop() || ((_a = globalState_1.GlobalState.service) === null || _a === void 0 ? void 0 : _a.cannotBeStopped))
            return;
        globalState_1.GlobalState.service = new azure_1.Azure();
        globalState_1.GlobalState.service.start(options);
    }
    static stop() {
        var _a;
        if (globalState_1.GlobalState.doubleClickDetector())
            return true;
        if ((_a = globalState_1.GlobalState.service) === null || _a === void 0 ? void 0 : _a.recognizing) {
            globalState_1.GlobalState.service.stop();
        }
        return false;
    }
    static endCommandMode() {
        if (globalState_1.GlobalState.service)
            commandUtils_1.CommandUtils.toggleCommandModeOff(globalState_1.GlobalState.service);
    }
}
exports.default = SpeechToElement;
