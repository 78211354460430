"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AzureSpeechConfig = void 0;
const readme_1 = require("../../consts/readme");
class AzureSpeechConfig {
    static validateOptions(onError, options) {
        if (!options) {
            onError(`Please provide subscription details - more info: ${readme_1.README_URL}`);
            return false;
        }
        if (!options.subscriptionKey && !options.token && !options.retrieveToken) {
            onError(`Please define a 'subscriptionKey', 'token' or 'retrieveToken' property - more info: ${readme_1.README_URL}`);
            return false;
        }
        if (!options.region) {
            onError(`Please define a 'region' property - more info: ${readme_1.README_URL}`);
            return false;
        }
        return true;
    }
    static async getNewSpeechConfig(sdkSpeechConfig, options) {
        if (!options.region)
            return;
        if (options.subscriptionKey) {
            return sdkSpeechConfig.fromSubscription(options.subscriptionKey.trim(), options.region.trim());
        }
        if (options.token) {
            return sdkSpeechConfig.fromAuthorizationToken(options.token.trim(), options.region.trim());
        }
        if (options.retrieveToken) {
            return options
                .retrieveToken()
                .then((token) => {
                return options.region
                    ? sdkSpeechConfig.fromAuthorizationToken((token === null || token === void 0 ? void 0 : token.trim()) || '', options.region.trim())
                    : null;
            })
                .catch((err) => {
                console.error(err);
                return null;
            });
        }
        return null;
    }
    static process(sdkSpeechConfig, options) {
        if (options.language)
            sdkSpeechConfig.speechRecognitionLanguage = options.language.trim();
    }
    static async get(sdkConfigType, options) {
        const speechConfig = await AzureSpeechConfig.getNewSpeechConfig(sdkConfigType, options);
        if (speechConfig)
            AzureSpeechConfig.process(speechConfig, options);
        return speechConfig;
    }
}
exports.AzureSpeechConfig = AzureSpeechConfig;
