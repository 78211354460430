"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventListeners = void 0;
class EventListeners {
    static getElementIfFocusedOnAvailable(available, focused) {
        if (Array.isArray(available)) {
            return available.find((element) => focused === element);
        }
        return focused === available ? available : undefined;
    }
    static keyDownWindow(options) {
        if (!options.element)
            return;
        if (EventListeners.getElementIfFocusedOnAvailable(options.element, document.activeElement)) {
            if (EventListeners.KEY_DOWN_TIMEOUT !== null)
                clearTimeout(EventListeners.KEY_DOWN_TIMEOUT);
            EventListeners.KEY_DOWN_TIMEOUT = setTimeout(() => {
                EventListeners.KEY_DOWN_TIMEOUT = null;
                this.resetRecording(options);
            }, 500); // this is used to prevent having to reset for every key down
        }
    }
    static mouseDownWindow(available, event) {
        this.mouseDownElement = EventListeners.getElementIfFocusedOnAvailable(available, event.target);
    }
    static mouseUpWindow(options) {
        if (this.mouseDownElement) {
            this.resetRecording(options);
        }
        this.mouseDownElement = undefined;
    }
    static add(speech, options) {
        const insertInNewLocation = (options === null || options === void 0 ? void 0 : options.insertInCursorLocation) === undefined || (options === null || options === void 0 ? void 0 : options.insertInCursorLocation);
        if ((options === null || options === void 0 ? void 0 : options.element) && insertInNewLocation) {
            speech.mouseDownEvent = EventListeners.mouseDownWindow.bind(speech, options.element);
            document.addEventListener('mousedown', speech.mouseDownEvent);
            speech.mouseUpEvent = EventListeners.mouseUpWindow.bind(speech, options);
            document.addEventListener('mouseup', speech.mouseUpEvent);
            speech.keyDownEvent = EventListeners.keyDownWindow.bind(speech, options);
            document.addEventListener('keydown', speech.keyDownEvent);
        }
    }
    static remove(speech) {
        document.removeEventListener('mousedown', speech.mouseDownEvent);
        document.removeEventListener('mouseup', speech.mouseUpEvent);
        document.removeEventListener('keydown', speech.keyDownEvent);
    }
}
exports.EventListeners = EventListeners;
EventListeners.KEY_DOWN_TIMEOUT = null;
