"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreventConnectionStop = void 0;
// This is a mechanism used to prevent two azure services starting at once as it cannot actually be stopped when connecting
// however if it does not connect in 800 milliseconds, the user has an option to start a new connection
class PreventConnectionStop {
    static applyPrevention(azure) {
        clearTimeout(azure._manualConnectionStopPrevention);
        azure.cannotBeStopped = true;
        azure._manualConnectionStopPrevention = setTimeout(() => {
            azure.cannotBeStopped = false;
        }, 800);
    }
    static clearPrevention(azure) {
        clearTimeout(azure._manualConnectionStopPrevention);
        azure.cannotBeStopped = false;
    }
}
exports.PreventConnectionStop = PreventConnectionStop;
