"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Padding = void 0;
const elements_1 = require("./elements");
const cursor_1 = require("./cursor");
const text_1 = require("./text");
class Padding {
    static setStateForPrimitiveElement(speech, element) {
        if (document.activeElement === element && element.selectionStart !== null) {
            const startIndex = element.selectionStart;
            const leftCharacter = element.value[startIndex - 1];
            const endIndex = element.selectionEnd === null ? startIndex : element.selectionEnd;
            const rightCharacter = element.value[endIndex];
            if (text_1.Text.isCharDefined(leftCharacter)) {
                speech.startPadding = ' ';
                speech.numberOfSpacesBeforeNewText = 1;
            }
            if (text_1.Text.isCharDefined(rightCharacter)) {
                speech.endPadding = ' ';
                speech.numberOfSpacesAfterNewText = 1;
            }
            speech.isCursorAtEnd = element.value.length === endIndex;
            return;
        }
        const lastCharacter = element.value[element.value.length - 1];
        if (text_1.Text.isCharDefined(lastCharacter)) {
            speech.startPadding = ' ';
            speech.numberOfSpacesBeforeNewText = 1;
        }
        speech.isCursorAtEnd = true;
    }
    static setStateForGenericElement(speech, element) {
        var _a, _b, _c;
        if (document.activeElement === element) {
            const selection = window.getSelection();
            if (selection === null || selection === void 0 ? void 0 : selection.focusNode) {
                const startIndex = cursor_1.Cursor.getGenericElementCursorOffset(element, selection, true);
                const leftCharacter = (_a = element.textContent) === null || _a === void 0 ? void 0 : _a[startIndex - 1];
                const endIndex = cursor_1.Cursor.getGenericElementCursorOffset(element, selection, false);
                const rightCharacter = (_b = element.textContent) === null || _b === void 0 ? void 0 : _b[endIndex];
                if (text_1.Text.isCharDefined(leftCharacter))
                    speech.startPadding = ' ';
                if (text_1.Text.isCharDefined(rightCharacter))
                    speech.endPadding = ' ';
                speech.isCursorAtEnd = ((_c = element.textContent) === null || _c === void 0 ? void 0 : _c.length) === endIndex;
                return;
            }
        }
        const lastCharacter = element.innerText.charAt(element.innerText.length - 1);
        if (text_1.Text.isCharDefined(lastCharacter))
            speech.startPadding = ' ';
        speech.isCursorAtEnd = true;
    }
    static setState(speech, element) {
        if (elements_1.Elements.isPrimitiveElement(element)) {
            Padding.setStateForPrimitiveElement(speech, element);
        }
        else {
            Padding.setStateForGenericElement(speech, element);
        }
    }
    static adjustStateAfterRecodingPrimitiveElement(speech, input) {
        speech.primitiveTextRecorded = true;
        if (speech.insertInCursorLocation && document.activeElement === input) {
            if (input.selectionEnd !== null) {
                speech.endPadding = speech.endPadding + input.value.slice(input.selectionEnd);
            }
            if (input.selectionStart !== null) {
                speech.startPadding = input.value.slice(0, input.selectionStart) + speech.startPadding;
                return;
            }
        }
        // this needs to be set to not retain the existing text
        speech.startPadding = input.value + speech.startPadding;
    }
    static adjustSateForNoTextPrimitiveElement(speech) {
        if (speech.numberOfSpacesBeforeNewText === 1) {
            speech.startPadding = speech.startPadding.substring(0, speech.startPadding.length - 1);
            speech.numberOfSpacesBeforeNewText = 0;
        }
        if (speech.numberOfSpacesAfterNewText === 1) {
            speech.endPadding = speech.endPadding.substring(1);
            speech.numberOfSpacesAfterNewText = 0;
        }
    }
}
exports.Padding = Padding;
