"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommandUtils = void 0;
const autoScroll_1 = require("./autoScroll");
const elements_1 = require("./elements");
const browser_1 = require("./browser");
const cursor_1 = require("./cursor");
const text_1 = require("./text");
class CommandUtils {
    static processCommand(command, settings) {
        if (!settings || !settings.caseSensitive)
            command = command.toLowerCase();
        return (settings === null || settings === void 0 ? void 0 : settings.substrings) === false ? text_1.Text.breakupIntoWordsArr(command) : command;
    }
    static process(commands) {
        var _a;
        if (((_a = commands.settings) === null || _a === void 0 ? void 0 : _a.caseSensitive) === true)
            return commands;
        const internalCommands = Object.keys(commands).reduce((prev, current) => {
            const property = commands[current];
            prev[current] = typeof property === 'string' ? CommandUtils.processCommand(property, commands.settings) : property;
            return prev;
        }, {});
        return internalCommands;
    }
    static toggleCommandModeOn(speech) {
        var _a;
        speech.isWaitingForCommand = true;
        (_a = speech.onCommandModeTrigger) === null || _a === void 0 ? void 0 : _a.call(speech, true);
    }
    static toggleCommandModeOff(speech) {
        var _a;
        if (speech.isWaitingForCommand) {
            (_a = speech.onCommandModeTrigger) === null || _a === void 0 ? void 0 : _a.call(speech, false);
            speech.isWaitingForCommand = false;
        }
    }
    static setText(speech, options, newText, element) {
        CommandUtils.toggleCommandModeOff(speech);
        if (elements_1.Elements.isPrimitiveElement(element)) {
            element.value = newText;
            if (!speech.isTargetInShadow)
                cursor_1.Cursor.setOffsetForPrimitive(element, newText.length, true);
            if (browser_1.Browser.IS_SAFARI() && speech.autoScroll)
                autoScroll_1.AutoScroll.scrollSafariPrimitiveToEnd(element);
        }
        else {
            element.textContent = newText;
            if (!speech.isTargetInShadow)
                cursor_1.Cursor.focusEndOfGeneric(element);
            setTimeout(() => autoScroll_1.AutoScroll.scrollGeneric(speech, element));
        }
        speech.resetRecording(options);
    }
    static checkIfMatchesSubstring(command, text) {
        return text.includes(command);
    }
    static checkIfMatchesWord(command, _, textArr) {
        const commandWords = command;
        for (let i = textArr.length - 1; i >= 0; i -= 1) {
            let textI = i;
            let commandI = commandWords.length - 1;
            while (textArr[textI] === commandWords[commandI] && commandI >= 0) {
                textI -= 1;
                commandI -= 1;
            }
            if (commandI < 0)
                return true;
        }
        return false;
    }
    // prettier-ignore
    static execCommand(speech, newText, options, element, originalText) {
        var _a, _b, _c;
        const commands = speech.commands;
        if (!commands || !element || !options)
            return;
        const text = ((_a = commands.settings) === null || _a === void 0 ? void 0 : _a.caseSensitive) === true ? newText : newText.toLowerCase();
        const textArr = text_1.Text.breakupIntoWordsArr(text);
        const check = ((_b = commands.settings) === null || _b === void 0 ? void 0 : _b.substrings) === false ? CommandUtils.checkIfMatchesWord : CommandUtils.checkIfMatchesSubstring;
        if (commands.commandMode && check(commands.commandMode, text, textArr)) {
            speech.setInterimColorToFinal();
            setTimeout(() => CommandUtils.toggleCommandModeOn(speech));
            return { doNotProcessTranscription: false };
        }
        if (commands.commandMode && !speech.isWaitingForCommand)
            return;
        if (commands.stop && check(commands.stop, text, textArr)) {
            CommandUtils.toggleCommandModeOff(speech);
            setTimeout(() => speech.stop());
            return { doNotProcessTranscription: false };
        }
        if (commands.pause && check(commands.pause, text, textArr)) {
            CommandUtils.toggleCommandModeOff(speech);
            speech.setInterimColorToFinal();
            setTimeout(() => {
                var _a;
                speech.isPaused = true;
                (_a = speech.onPauseTrigger) === null || _a === void 0 ? void 0 : _a.call(speech, true);
            });
            return { doNotProcessTranscription: false };
        }
        if (commands.resume && check(commands.resume, text, textArr)) {
            speech.isPaused = false;
            (_c = speech.onPauseTrigger) === null || _c === void 0 ? void 0 : _c.call(speech, false);
            CommandUtils.toggleCommandModeOff(speech);
            speech.resetRecording(options);
            return { doNotProcessTranscription: true };
        }
        if (commands.reset && check(commands.reset, text, textArr)) {
            if (originalText !== undefined)
                CommandUtils.setText(speech, options, originalText, element);
            return { doNotProcessTranscription: true };
        }
        if (commands.removeAllText && check(commands.removeAllText, text, textArr)) {
            CommandUtils.setText(speech, options, '', element);
            return { doNotProcessTranscription: true };
        }
        return;
    }
}
exports.CommandUtils = CommandUtils;
