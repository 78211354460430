"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AzureTranscript = void 0;
const translate_1 = require("../../utils/translate");
class AzureTranscript {
    // newText is used to only send new text in onResult as finalTranscript is continuously accumulated
    static extract(text, finalTranscript, isFinal, translations) {
        if (translations)
            text = translate_1.Translate.translate(text, translations);
        if (isFinal) {
            return { interimTranscript: '', finalTranscript: finalTranscript + text, newText: text };
        }
        return { interimTranscript: text, finalTranscript, newText: text };
    }
}
exports.AzureTranscript = AzureTranscript;
