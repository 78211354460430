"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreResultUtils = void 0;
class PreResultUtils {
    static process(speech, newText, isFinal, onPreResult, options) {
        const result = onPreResult === null || onPreResult === void 0 ? void 0 : onPreResult(newText, isFinal);
        if (!result)
            return false;
        // this is to prevent the spans from being repopulated after service stopped
        setTimeout(() => {
            if (result.restart) {
                speech.resetRecording(options);
            }
            else if (result.stop) {
                speech.stop();
            }
        });
        // removeNewText can only work reliably if stop or restart are used as otherwise the service will keep sending text
        return (result.stop || result.restart) && result.removeNewText;
    }
}
exports.PreResultUtils = PreResultUtils;
