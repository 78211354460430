"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Text = void 0;
class Text {
    static capitalize(text) {
        return text.replace(Text.FIRST_CHAR_REGEX, (word) => {
            return word.toUpperCase();
        });
    }
    static lineBreak(text) {
        return text.replace(Text.DOUBLE_LINE, '<p></p>').replace(Text.ONE_LINE, '<br>');
    }
    static isCharDefined(char) {
        return char !== undefined && char !== ' ' && char !== ' ' && char !== '\n' && char !== '';
    }
    static breakupIntoWordsArr(text) {
        return text.split(/(\W+)/);
    }
}
exports.Text = Text;
Text.FIRST_CHAR_REGEX = /\S/;
Text.DOUBLE_LINE = /\n\n/g;
Text.ONE_LINE = /\n/g;
