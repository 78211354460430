"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Highlight = void 0;
const elements_1 = require("./elements");
const cursor_1 = require("./cursor");
class Highlight {
    static setStateForPrimitive(speech, element) {
        let startIndex;
        let endIndex;
        if (element.selectionStart !== null)
            startIndex = element.selectionStart;
        if (element.selectionEnd !== null)
            endIndex = element.selectionEnd;
        speech.isHighlighted = startIndex !== endIndex;
    }
    static setStateForGeneric(speech, element) {
        const selection = window.getSelection();
        if (selection === null || selection === void 0 ? void 0 : selection.focusNode) {
            const startIndex = cursor_1.Cursor.getGenericElementCursorOffset(element, selection, true);
            const endIndex = cursor_1.Cursor.getGenericElementCursorOffset(element, selection, false);
            speech.isHighlighted = startIndex !== endIndex;
        }
    }
    static setState(speech, element) {
        if (document.activeElement === element) {
            if (elements_1.Elements.isPrimitiveElement(element)) {
                Highlight.setStateForPrimitive(speech, element);
            }
            else {
                Highlight.setStateForGeneric(speech, element);
            }
        }
    }
    static removeForGeneric(speech, element) {
        const selection = window.getSelection();
        if (selection) {
            const startOffset = cursor_1.Cursor.getGenericElementCursorOffset(element, selection, true);
            selection.deleteFromDocument();
            cursor_1.Cursor.setOffsetForGeneric(element, startOffset);
            speech.isHighlighted = false;
        }
    }
    static removeForPrimitive(speech, element) {
        const selectionStart = element.selectionStart;
        const selectionEnd = element.selectionEnd;
        const currentValue = element.value;
        if (selectionStart && selectionEnd) {
            const newValue = currentValue.substring(0, selectionStart) + currentValue.substring(selectionEnd);
            element.value = newValue;
            cursor_1.Cursor.setOffsetForPrimitive(element, selectionStart, speech.autoScroll);
        }
        speech.isHighlighted = false;
    }
}
exports.Highlight = Highlight;
