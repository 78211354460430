"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Browser = void 0;
class Browser {
}
exports.Browser = Browser;
// In a function in order to use navigator later as otherwise ssr (nextjs) fails
Browser.IS_SAFARI = () => {
    if (Browser._IS_SAFARI === undefined) {
        Browser._IS_SAFARI = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }
    return Browser._IS_SAFARI;
};
